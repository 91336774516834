"use client"

import React, { useState, useContext, SVGProps } from "react"

import {
  DMLabel,
  hexToOklch,
  normalizeTestId,
  DMThemeGenerator,
} from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import * as SwitchPrimitives from "@radix-ui/react-switch"

import { getLabel, Label, SwitchProps } from "../types"

function getCommonClassNames(isLabelRight?: boolean) {
  return cn("flex grow-0 items-center gap-8 relative overflow-hidden", {
    "flex-row-reverse": !isLabelRight,
  })
}

type DMSwitchProps = SwitchProps & {
  isReadOnly?: false
  label?: Label | boolean
  className?: string
  dataTestId?: string
}

type ReadOnlyDMSwitchProps = SwitchProps & {
  isReadOnly: true
  label: Label | true
  dataTestId?: string
}

function IconCheckMedium(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 6L7 8L11 4"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function IconCheckSmall(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.75 6L7.25 7.5L10.25 4.5"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function IconCheckTiny(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 4.99999L5.33333 6.33332L8 3.66666"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const DMSwitch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  DMSwitchProps | ReadOnlyDMSwitchProps
>((props, ref) => {
  const { isReadOnly, label, isChecked: checked, dataTestId } = props
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(checked))
  const actualChecked = props.isChecked ?? isChecked

  const themeContext = useContext(DMThemeGenerator)
  const accentColor = themeContext?.accentColor ?? "#000000"

  const { l } = hexToOklch(accentColor)
  const isDark = l < 0.62

  if (isReadOnly && "isReadOnly" in props) {
    return (
      <DMLabel className="text-body-small cursor-text font-semibold">
        {getLabel({ isChecked, label })}
      </DMLabel>
    )
  }

  const {
    className,
    id,
    isLabelRight,
    onChange,
    isDisabled,
    size = "small",
  } = props

  const handleOnCheckedChange = (changed: boolean) => {
    setIsChecked(changed)
    onChange?.(changed)
  }

  return (
    <div className={getCommonClassNames(isLabelRight)}>
      <SwitchPrimitives.Root
        ref={ref}
        checked={actualChecked}
        className={cn(
          "inline-flex cursor-pointer items-center rounded-[999px] transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 disabled:cursor-not-allowed relative",
          "data-[state=checked]:bg-primary",
          "data-[state=unchecked]:bg-[--rs-color-foreground-disabled]",
          "data-[state=unchecked]:hover:bg-[--rs-color-foreground-neutral-placeholder]",
          "data-[disabled]:data-[state=checked]:bg-disabled",
          "data-[disabled]:data-[state=checked]:hover:bg-disabled",
          "data-[disabled]:data-[state=unchecked]:bg-disabled",
          "data-[disabled]:data-[state=unchecked]:hover:bg-disabled",
          {
            "h-[20px] w-[32px]": size === "medium",
            "h-[16px] w-[28px]": size === "small",
            "h-[14px] w-[22px]": size === "tiny",
          },
          className
        )}
        disabled={isDisabled}
        data-test-id={normalizeTestId(dataTestId)}
        id={id}
        onCheckedChange={handleOnCheckedChange}
      >
        <div
          className={cn(
            "absolute",
            {
              "text-white": isDark,
              "text-[var(--rs-color-background-page)]": !isDark,
            },
          )}
        >
          {size === "tiny" ? <IconCheckTiny /> : size === "small" ? <IconCheckSmall /> : <IconCheckMedium />}
        </div>
        <SwitchPrimitives.Thumb
          className={cn(
            "rounded-[999px] bg-white transition-transform",
            "data-[disabled]:shadow-raised",
            "data-[state=unchecked]:translate-x-[2px]",
            {
              "bg-page": !isDark,
            },
            {
              "data-[state=checked]:translate-x-[14px]": size !== "tiny",
              "data-[state=checked]:translate-x-[10px]": size === "tiny",
            },
            {
              "h-icon-small w-icon-small": size === "medium",
              "h-[12px] w-[12px]": size === "small",
              "h-[10px] w-[10px]": size === "tiny",
            }
          )}
        />
      </SwitchPrimitives.Root>
      {label && (
        <DMLabel
          className={cn(
            "text-body-small font-semibold",
            !!isDisabled && "text-disabled cursor-not-allowed"
          )}
          htmlFor={id}
        >
          {getLabel({ isChecked: actualChecked, label })}
        </DMLabel>
      )}
    </div>
  )
})

DMSwitch.displayName = SwitchPrimitives.Root.displayName

export { DMSwitch }
